import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import PropTypes from 'prop-types';
import {Config} from "../../../Config";
import { connect } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';

class ExportButton extends Component {

    handleClick = () => {
        const {record, showNotification } = this.props;

        window.location.href = `${Config.api.entryPoint}/trainings/export/${record.id}`;
        showNotification('Participants');
    }

    render() {
        return (
            <Button color={'primary'} size={'small'} onClick={this.handleClick}>
                <GetAppIcon />
                Participants
            </Button>
        );
    }
}

ExportButton.propTypes = {
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
})(ExportButton);
