import React from 'react';
import {
    Create, SimpleForm, TextInput, required, ReferenceInput, SelectInput, NumberInput, maxLength
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import CustomImageInput from '../CustomImageInput';
import CustomFileInput from '../CustomFileInput';
import RichTextInputConfig from './../../Config/RichTextInputConfig';

export class ArticleCreate extends React.Component {
    render() {
        return (
            <Create {...this.props} title="Article">
                <SimpleForm redirect="show">
                    <ReferenceInput source="category" reference="article_categories" label="Emplacement"
                                    validate={required()}>
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                    <ReferenceInput source="status" reference="article_statuses" label="Status" validate={required()}>
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                    <TextInput source="title" label="Titre" toolbar={RichTextInputConfig}
                               validate={[required(), maxLength(255)]}/>
                    <CustomImageInput label={"Image de présentation de l'article"}/>
                    <RichTextInput source="description" label="Description"
                                   toolbar={RichTextInputConfig}
                                   validate={[required(), maxLength(150)]}/>
                    <RichTextInput source="content" label="Contenu" toolbar={RichTextInputConfig}
                                   validate={required()}/>
                    <NumberInput source="ranking" label="Ordre" helperText="Les articles sont classés par ordre décroissant" defaultValue={0}
                                 validate={required()}/>
                    <CustomFileInput label={"Uploader une pièce jointe à l'article"}/>
                    <TextInput source="image" label="Image" style={{display: 'none'}}/>

                </SimpleForm>
            </Create>);
    }
}
