import React from 'react';
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  required,
  SelectInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {DateTimeInput} from 'react-admin-date-inputs';
import frLocale from "date-fns/locale/fr";
import MomentUtils from '@date-io/moment';
import TrainigStatuses from './Config/TrainigStatuses';
import CustomImageInput from '../CustomImageInput';
import RichTextInputConfig from './../../Config/RichTextInputConfig';

MomentUtils.prototype.getStartOfMonth=MomentUtils.prototype.startOfMonth;

export const TrainingEdit = (props) => (
  <Edit {...props} title="Formation">
    <SimpleForm>
      <DisabledInput source="id" label="ID"/>
      <SelectInput source="status" choices={TrainigStatuses}/>
      <TextInput source="name" label="Intitulé" validate={required()}/>
      <RichTextInput source="shortDescription" label="Résumé" toolbar={RichTextInputConfig} validate={required()}/>
      <RichTextInput source="description" label="Description" toolbar={RichTextInputConfig} validate={required()}/>
      <NumberInput source="numberMinOfParticipants" label="Nombre minimum de participants"
                   validate={required()}/>
      <NumberInput source="numberMaxOfParticipants" label="Nombre maximum de participants"
                   validate={required()}/>
      <ReferenceArrayInput source="memberIds" reference="members" label="Participants" filterToQuery={searchText => ({ search: searchText })}>
        <AutocompleteArrayInput optionText={member => member.lastName + ' ' + member.firstName} />
      </ReferenceArrayInput>

        <ReferenceArrayInput source="waitingMemberIds" reference="members" label="Liste d'attente"
                             filterToQuery={searchText => ({search: searchText})}>
            <AutocompleteArrayInput optionText={member => member.lastName + ' ' + member.firstName}/>
        </ReferenceArrayInput>

      <CustomImageInput label={"Modifier l'image de présentation de la formation"} />

      <ArrayInput source="events" label="Dates">
        <SimpleFormIterator>
          <DateTimeInput source="dateStart" label="Commence le" validate={required()}
                         options={{format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true}}
                         providerOptions={{utils: MomentUtils, locale: frLocale}}/>
          <DateTimeInput source="dateEnd" label="Fini le" validate={required()}
                         options={{format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true}}
                         providerOptions={{utils: MomentUtils, locale: frLocale}}/>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
