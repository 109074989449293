import React from 'react';
import {
    Edit, SimpleForm, DisabledInput, TextInput, required, maxLength
} from 'react-admin';
import CustomFileInput from "../CustomFileInput";


export const DocumentEdit = (props) => {

    return (
        <Edit {...props} title="Article">
            <SimpleForm>
                <DisabledInput source="uuid" label="ID"/>
                <TextInput source="title" label="Titre" validate={[required(), maxLength(255)]}/>
                <CustomFileInput label={"Uploader un fichier"} many={false} />
                <TextInput source="file" label="Fichier" style={{display: 'none'}} validate={[required()]} />
            </SimpleForm>
        </Edit>
    )
}
