import React from 'react';
import { Edit, SaveButton, Toolbar,  SimpleForm, DisabledInput, TextInput, required, maxLength, email, BooleanInput, ArrayInput, ReferenceInput, SelectInput, SimpleFormIterator, DateInput, ReferenceArrayInput, SelectArrayInput, AutocompleteArrayInput } from 'react-admin';

export const MemberEdit = (props) => (
    <Edit {...props} title="Membre">
        <SimpleForm >
            <DisabledInput source="uuid" label="ID"/>
            <TextInput source="email" label="Email *" type="email" validate={ email() } />
            <ReferenceInput source="civility" reference="civilities" label="Civilité">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="firstName" label="Prénom" validate={ [required(), maxLength(255)] } />
            <TextInput source="lastName" label="Nom" validate={ [required(), maxLength(255)] } />
            <TextInput source="address" label="Adresse" validate={maxLength(255)}/>
            <TextInput source="postalCode" label="Code postal" validate={maxLength(10)}/>
            <TextInput source="phoneNumber" label="Télephone" validate={maxLength(20)}/>
            <BooleanInput label="Actif" source="enabled"/>
            <ReferenceArrayInput source="associations" reference="associations" label="Organisme" filterToQuery={searchText => ({ search: searchText })}>
              <AutocompleteArrayInput optionText={organisme => organisme.mapZoneLabel + ' - ' + organisme.name} />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="assignments" reference="assignments" label="Assignation" validate={ required() }>
                <SelectArrayInput optionText="label" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
