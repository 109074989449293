import React from 'react';
import {
    Edit, SimpleForm, DisabledInput, TextInput, required, ReferenceInput, SelectInput,
    maxLength, NumberInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import CustomImageInput from '../CustomImageInput';
import CustomFileInput from "./../CustomFileInput";
import  RichTextInputConfig from './../../Config/RichTextInputConfig';


export const ArticleEdit = (props) => {

    return (
        <Edit {...props} title="Article">
            <SimpleForm>
                <DisabledInput source="uuid" label="ID"/>
                <ReferenceInput source="category" reference="article_categories" label="Emplacement"
                                validate={required()}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <ReferenceInput source="status" reference="article_statuses" label="Status" validate={required()}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput source="title" label="Titre" validate={[required(), maxLength(255)]}/>

                <CustomImageInput label={"Modifier l'mage de présentation de l'article"}/>
                <RichTextInput source="description" label="Description" toolbar={RichTextInputConfig} validate={[required(), maxLength(150)]}/>
                <RichTextInput source="content" label="Contenu" toolbar={RichTextInputConfig} validate={required()}/>
                <NumberInput source="ranking" label="Ordre" helperText="Les articles sont classés par ordre décroissant" defaultValue={0}
                             validate={required()}/>
                <CustomFileInput label={"Uploader une pièce jointe à l'article"} {...props}/>
                <TextInput source="image" label="Image" style={{display: 'none'}}/>
            </SimpleForm>
        </Edit>
    )
};
