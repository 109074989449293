import React, {Fragment} from 'react';
import {
    Create, SimpleForm, TextInput, required, ReferenceInput, SelectInput, SelectArrayInput, ArrayInput,
    SimpleFormIterator, maxLength, ImageInput, ImageField, REDUX_FORM_NAME, FormDataConsumer, fetchEnd, fetchStart,
    showNotification
} from 'react-admin';
import {Config} from "../Config";
import {change} from 'redux-form';
import {connect} from 'react-redux';
import AD13Loader from '../Components/AD13Loader';

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const Preview = (props) => {
    return (<div>
        {props.previewImages.map(file => {
            return (<div style={thumb} key={file.id ? file.id : file.uuid}>
                    <div style={thumbInner}>
                        <img
                            src={file.url}
                            style={img}
                        />
                    </div>
                </div>
            )
        })}

    </div>)
};

class CustomImageInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            previewImages: [],
            loading: false
        };
    }

    componentDidMount() {
        let image = [];
        if (this.props.hasOwnProperty('record') && this.props.record.hasOwnProperty('image')) {
            image = this.props.record.image;

            if ('object' === typeof image && null !== image) {
                this.setState(state => ({previewImages: state.previewImages.concat([image])}));
            } else if (null !== image) {
                fetch(Config.api.entryPoint + image, {method: 'GET'})
                    .then(res => res.json())
                    .then(data => {
                        this.setState(state => ({previewImages: state.previewImages.concat([data])}));
                    });
            }

        }
    }


    onDrop = (acceptedFiles) => {

        if (0 === acceptedFiles.length) {
            this.props.showNotification('Le fichier n\'a pu être uploadé, ce doit être une image faisant moins de 2 Mo', 'warning');
        }

        acceptedFiles.forEach(file => {
                const body = new FormData();
                body.append('file', file);

                this.setState({
                    loading: true
                });

                return fetch(`${Config.api.entryPoint}/media_objects`, {body, method: 'POST'})
                    .then(response => {
                        response.json().then(data => {

                            this.props.recordImageField(data['@id']);

                            this.setState(state => {
                                    return {previewImages: [data], loading: false}
                                }
                            );

                        });
                    });
            }
        );
    };

    render() {

        return (
            <Fragment>
                {true === this.state.loading && <AD13Loader/> ||
                <ImageInput source="image" label={this.props.label} options={{onDrop: this.onDrop, maxSize: 2 * Math.pow(1024, 2)}}
                            accept="image/*">
                </ImageInput>
                }
                <Preview previewImages={this.state.previewImages}/>
            </Fragment>
        )
    }
}

export default connect(null, {
    showNotification,
    recordImageField: val => change('record-form', "image", val),
})(CustomImageInput);
