import {Config} from './Config'
import decodeJwt from 'jwt-decode';
import {HttpError} from 'react-admin';

const customDataProvider = requestHandler => (type, resource, params) => {
    let token = window.localStorage.getItem('token');
    let tokenDecoded = decodeJwt(token);
    let myHeaders = new Headers();
    let fetchConfig = {};

    if ('trainings' === resource) {
        switch (type) {
            case 'GET_ONE':

                let id = params['id'].match(/^\/trainings\//) ? params['id'] : '/trainings/' + params['id'];

                fetchConfig = {
                    fetchUrl: Config.api.entryPoint + id,
                    fetchOptions: {
                        method: 'GET',
                    },
                    fetchCallback: response => {
                        response.memberIds = response.registeredMembers.map(member => '/members/' + member.id);
                        response.waitingMemberIds = response.waitingMembers.map(member => '/members/' + member.id);
                        return {
                            data: response
                        };
                    }
                };
                break;
            case 'GET_LIST':
                let filterQuery = Object.keys(params.filter).reduce((accumulator, key) => accumulator + '&' + key + '=' + params.filter[key], '');

                fetchConfig = {
                    fetchUrl: Config.api.entryPoint + '/trainings?order[' + params.sort.field + ']=' + params.sort.order + '&perPage=' + params.pagination.perPage + '&page=' + params.pagination.page + filterQuery,
                    fetchOptions: {
                        method: 'GET',
                    },
                    fetchCallback: response => {
                        return {
                            data: response['hydra:member'],
                            total: response['hydra:totalItems']
                        };
                    }
                };
                break;
            case 'CREATE':

                if (false === params.data.hasOwnProperty('events') || 0 === params.data.events.length) {
                    throw new HttpError('Un créneau horaire est requis');
                }
                myHeaders.append('authorization', 'Bearer ' + token);
                myHeaders.append('content-type', 'application/json');
                fetchConfig = {
                    fetchUrl: Config.api.entryPoint + '/cqrs/commands/app_ad13_application_training_create_one_training',
                    fetchOptions: {
                        headers: myHeaders,
                        method: 'POST',
                        body: JSON.stringify({
                            name: params.data.name,
                            status: params.data.status,
                            shortDescription: params.data.shortDescription,
                            description: params.data.description,
                            numberMinOfParticipants: params.data.numberMinOfParticipants,
                            numberMaxOfParticipants: params.data.numberMaxOfParticipants,
                            numberOfParticipants: params.data.numberOfParticipants,
                            memberIds: params.data.memberIds ? params.data.memberIds.map(iri => iri.replace('/members/', '')) : [],
                            waitingMemberIds: params.data.waitingMemberIds ? params.data.waitingMemberIds.map(iri => iri.replace('/members/', '')) : [],
                            imageId: params.data.hasOwnProperty('image') ? params.data.image.replace('/media_objects/', '') : null,
                            creatorId: tokenDecoded.user.uuid,
                            events: params.data.events
                        })
                    },
                    fetchCallback: response => {
                        return {
                            data: {id: response.id}
                        };
                    }
                };
                break;
            case 'UPDATE':
                if (false === params.data.hasOwnProperty('events') || 0 === params.data.events.length) {
                    throw new HttpError('Un créneau horaire est requis');
                }
                myHeaders.append('authorization', 'Bearer ' + token);
                myHeaders.append('content-type', 'application/json');
                fetchConfig = {
                    fetchUrl: Config.api.entryPoint + '/cqrs/commands/app_ad13_application_training_modify_one_training',
                    fetchOptions: {
                        headers: myHeaders,
                        method: 'POST',
                        body: JSON.stringify({
                            id: params.data.id,
                            name: params.data.name,
                            status: params.data.status,
                            shortDescription: params.data.shortDescription,
                            description: params.data.description,
                            numberMinOfParticipants: params.data.numberMinOfParticipants,
                            numberMaxOfParticipants: params.data.numberMaxOfParticipants,
                            numberOfParticipants: params.data.numberOfParticipants,
                            memberIds: params.data.memberIds ? params.data.memberIds.map(iri => iri.replace('/members/', '')) : [],
                            waitingMemberIds: params.data.waitingMemberIds ? params.data.waitingMemberIds.map(iri => iri.replace('/members/', '')) : [],
                            imageId: params.data.image ? (params.data.image.hasOwnProperty('id') ? params.data.image.id : params.data.image.replace('/media_objects/', '')) : null,
                            modifierId: tokenDecoded.user.uuid,
                            events: params.data.events
                        })
                    },
                    fetchCallback: response => {
                        return {
                            data: {id: response.id}
                        };
                    }
                };
                break;
            case 'DELETE':
                myHeaders.append('authorization', 'Bearer ' + token);
                myHeaders.append('content-type', 'application/json');
                fetchConfig = {
                    fetchUrl: Config.api.entryPoint + '/cqrs/commands/app_ad13_application_training_delete_one_training',
                    fetchOptions: {
                        headers: myHeaders,
                        method: 'POST',
                        body: JSON.stringify({
                            id: params.id,
                            deleterId: tokenDecoded.user.uuid,
                        })
                    },
                    fetchCallback: response => {
                        return {
                            data: {id: response.id}
                        };
                    }
                };
                break;

            case 'DELETE_MANY':
                myHeaders.append('authorization', 'Bearer ' + token);
                myHeaders.append('content-type', 'application/json');

                let requests = [];

                params.ids.forEach(id => {
                    requests.push(
                        fetch(Config.api.entryPoint + '/cqrs/commands/app_ad13_application_training_delete_one_training',
                            {
                                headers: myHeaders,
                                method: 'POST',
                                body: JSON.stringify({
                                    id: id,
                                    deleterId: tokenDecoded.user.uuid,
                                })
                            })
                            .then(res => res.json())
                            .then(fetchConfig.fetchCallback)
                    );
                });

                return Promise.all(requests).then(response => {
                    return {
                        data: response
                    };
                });
        }

        return fetch(fetchConfig.fetchUrl, fetchConfig.fetchOptions)
            .then(res => res.json())
            .then(fetchConfig.fetchCallback);
    }

    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default customDataProvider;
