import React, {Fragment} from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
    SelectArrayInput,
    ArrayInput,
    SimpleFormIterator,
    maxLength,
    FileInput,
    ImageField,
    REDUX_FORM_NAME,
    FormDataConsumer,
    fetchEnd,
    fetchStart,
    ReferenceArrayInput,
    Labeled
} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {change} from 'redux-form';
import {connect} from 'react-redux';
import {Config} from "../Config";


export default class CustomFileList extends React.Component {

    state = {previewFiles: []};


    constructor(props) {
        super(props);

        if (props.hasOwnProperty('previewFiles')) {
            this.state = {previewFiles: props.previewFiles};
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.hasOwnProperty('previewFiles')) {
            this.setState(state => {
                return {previewFiles: nextProps.previewFiles}
            });
        }
    }

    componentDidMount() {
        if (!this.props.hasOwnProperty('files')) {
            return;
        }

        this.props.files.forEach(fileId => {
            fetch(Config.api.entryPoint + fileId, {method: 'GET'})
                .then(res => res.json())
                .then(data => {

                    this.setState(state => {
                        return {previewFiles: state.previewFiles.concat([data])}
                    });
                });
        });

    }

    render() {
        return (0 < this.state.previewFiles.length && <Labeled label="Fichiers">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Nom</TableCell>
                        <TableCell align="left">Url</TableCell>
                        <TableCell align="left">Télécharger</TableCell>
                        {this.props.hasOwnProperty('onDelete') && <TableCell align="left">Supprimer</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.previewFiles.map(file => {
                        return (
                            <TableRow key={file.name}>
                                <TableCell>
                                    {file.originalName}
                                </TableCell>
                                <TableCell>
                                    <a href="javascript:void(0)" id={'file-' + file.uuid} onClick={() => {
                                        // Select the email link anchor text
                                        var fileLink = document.querySelector('#file-' + file.uuid);
                                        var range = document.createRange();
                                        range.selectNode(fileLink);
                                        window.getSelection().addRange(range);

                                        try {
                                            // Now that we've selected the anchor text, execute the copy command
                                            document.execCommand('copy');
                                        } catch(err) {
                                            console.log('Oops, unable to copy');
                                        }

                                        // Remove the selections - NOTE: Should use
                                        // removeRange(range) when it is supported
                                        window.getSelection().removeAllRanges();
                                    }} title={"Copier le lien"}>
                                        {file.url}
                                        <IconButton aria-label="Copier le lien">
                                            <LinkIcon/>
                                        </IconButton>
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <a href={file['url']} download title={"Télécharger"}>
                                        <IconButton aria-label="Télécharger">
                                            <CloudDownloadIcon/>
                                        </IconButton>
                                    </a>
                                </TableCell>
                                {this.props.hasOwnProperty('onDelete') && <TableCell>
                                    <IconButton title={"Supprimer"} aria-label="Supprimer" onClick={() => {
                                        this.props.onDelete(file['uuid'])
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Labeled>)
    }
}
