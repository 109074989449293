import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ShowButton,
    EditButton,
    NumberField,
    SelectField,
    Filter,
    TextInput
} from 'react-admin';
import TrainigStatuses from './Config/TrainigStatuses';
import DuplicateButton from "./Component/DuplicateButton";
import ExportButton from "./Component/ExportButton";

const TrainingFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Intitulé" source="name"/>
        <TextInput label="Status" source="status"/>
        <TextInput label="Nombre de participants" source="numberOfParticipants"/>
        <TextInput label="Nombre minimum de participants" source="numberMinOfParticipants"/>
        <TextInput label="Nombre maximum de participants" source="numberMaxOfParticipants"/>
    </Filter>
);


export const TrainingList = (props) => (
    <List {...props} title="Formations" filters={<TrainingFilter/>} perPage={30}>
        <Datagrid>
            <TextField source="name" label="Intitulé"/>
            <SelectField source="status" choices={TrainigStatuses} label="Status"/>
            <NumberField source="numberOfParticipants" label="Nombre de participants"/>
            <NumberField source="numberMinOfParticipants" label="Nombre minimum de participants"/>
            <NumberField source="numberMaxOfParticipants" label="Nombre maximum de participants"/>
            <DateField source="dateCreate" label="Date création"/>
            <ShowButton/>
            <EditButton/>
            <DuplicateButton/>
            <ExportButton />
        </Datagrid>
    </List>
);
