import React, {Component, Fragment} from 'react';
import {
    SimpleShowLayout,
    TextField,
    ReferenceField,
    NumberField,
    EditButton,
    RichTextField,
    ImageField,
    ShowView,
    ShowController
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import CustomFileList from "./../CustomFileList";

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const Actions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
    </CardActions>
);

export class ArticleShow extends Component {

    render() {
        return (
            <ShowController  {...this.props} title="Membre">
                {controllerProps => {
                    return <Fragment>
                        <ShowView actions={<Actions />} {...controllerProps}>
                            <SimpleShowLayout>
                                <TextField source="uuid" label="ID"/>
                                <ReferenceField source="category" reference="article_categories" label="Emplacement"
                                                linkType={false}>
                                    <TextField source="name"/>
                                </ReferenceField>
                                <ReferenceField source="status" reference="article_statuses" label="Status"
                                                linkType={false}>
                                    <TextField source="name"/>
                                </ReferenceField>
                                <TextField source="title" label="Titre"/>
                                <ReferenceField source="image" reference="media_objects" label="Image" linkType={false}>
                                    <ImageField source="url"/>
                                </ReferenceField>
                                <RichTextField source="description" label="Description"/>
                                <RichTextField source="content" label="Contenu"/>
                                <NumberField source="ranking" label="Ordre" defaultValue={0} />

                                {controllerProps.record && controllerProps.record.hasOwnProperty('files') &&
                                <CustomFileList label={"Uploader une pièce jointe à l'article"}
                                                files={controllerProps.record.files}/>}
                            </SimpleShowLayout>
                        </ShowView>
                    </Fragment>
                }
                }
            </ShowController>
        )
    }
};
