import React from 'react';
import {
    Create, SimpleForm, TextInput, required, maxLength
} from 'react-admin';
import CustomFileInput from "../CustomFileInput";

export class DocumentCreate extends React.Component {
    render() {
        return (
            <Create {...this.props} title="Document">
                <SimpleForm redirect="show">
                    <TextInput source="title" label="Titre" validate={[required(), maxLength(255)]}/>
                    <CustomFileInput label={"Uploader un fichier"} many={false} />
                    <TextInput source="file" label="Fichier" style={{display: 'none'}} validate={[required()]} />
                </SimpleForm>
            </Create>);
    }
}
