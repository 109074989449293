import React from 'react';
import {
    Show, SimpleShowLayout, TextField, DateField, EmailField, EditButton, ReferenceField,
    ChipField, email, required, BooleanField, NumberField, DisabledInput, RichTextField, ReferenceArrayField, Datagrid, ShowButton, TabbedShowLayout, Tab, ImageField
} from 'react-admin';

export const AssociationShow = (props) => (
    <Show { ...props } title="Commune">
        <TabbedShowLayout>
          <Tab label="Détail">
            <TextField source="uuid" label="ID"/>
            <TextField source="mapZoneLabel" label="Commune"/>
            <TextField source="name" label="Organisme"/>
            <BooleanField label="Adhérent AD13" source="partOfAD13" />
            <ReferenceField source="associationType" reference="association_types" label="Status" >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="address" label="Adresse" />
            <TextField source="email" label="Email" type="email" />
            <TextField source="websiteUrl" label="Site internet" type="url" />
            <TextField source="phoneNumber" label="Télephone" />
            <TextField source="accountant" label="Gérant" />
            <TextField source="assistant" label="Adjoint" />
            <NumberField source="numberOfMembers" label="Nombre de membres" />
            <TextField source="means" label="Moyens" />
            <RichTextField source="description" label="Description"/>
            <ReferenceField source="image" reference="media_objects" label="Image" linkType={false}>
              <ImageField source="url"/>
            </ReferenceField>
          </Tab>
          <Tab label="Membres">
            <ReferenceArrayField source="members" reference="members" label="">
              <Datagrid>
                <TextField label="Prénom" source="lastName" />
                <TextField label="Nom" source="firstName" />
                <ShowButton />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>
        </TabbedShowLayout>
    </Show>
);
