import React, {Component} from 'react';
import {Admin, Title, Resource, Login} from 'react-admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import authProvider from './authProvider';
import customDataProvider from './customDataProvider';
import {Redirect} from 'react-router-dom';
import {createMuiTheme} from '@material-ui/core/styles';

import {Config} from './Config';

// import Layout from './Component/Layout';
import {AssociationShow} from './Components/Association/Show';
import {AssociationEdit} from './Components/Association/Edit';
import {AssociationList} from './Components/Association/List';
import MemberShow from './Components/Member/Show';
import {MemberEdit} from './Components/Member/Edit';
import {MemberCreate} from './Components/Member/Create';
import {MemberList} from './Components/Member/List';
import {ArticleShow} from './Components/Article/Show';
import {ArticleEdit} from './Components/Article/Edit';
import {ArticleCreate} from './Components/Article/Create';
import {ArticleList} from './Components/Article/List';
import {TrainingList} from './Components/Training/List';
import {TrainingEdit} from './Components/Training/Edit';
import {TrainingShow} from './Components/Training/Show';
import {TrainingCreate} from './Components/Training/Create';
import {DocumentShow} from './Components/Document/Show';
import {DocumentEdit} from './Components/Document/Edit';
import {DocumentCreate} from './Components/Document/Create';
import {DocumentList} from './Components/Document/List';
import frenchMessages from 'ra-language-french';
import {customizeTranslations} from './Service/CustomizeTranlsations';
import {Card, CardContent, Grid, Typography} from '@material-ui/core/'
import {Group, NaturePeople, Note, School} from '@material-ui/icons/'
import AD13_Background from './assets/AD13_Background.jpg'
import darkRabbit from './assets/AD13_DarkRabbit.png';
import MuiTheme from './MuiTheme';
import AD13Loader from './Components/AD13Loader';
const theme = createMuiTheme(MuiTheme);

const entrypoint = Config.api.entryPoint;

const i18nProvider = locale => customizeTranslations(frenchMessages);

export default class extends Component {

    state = {
        api: null,
    };

    componentDidMount() {
        if (null !== window.localStorage.getItem('token')) {
            this.apiDocumentationParser(entrypoint).then(({api}) => {
                this.setState({api});
            }).catch((e) => {
                console.log(e);
            });
        }

    }

    fetchHeaders() {
        return null !== window.localStorage.getItem('token') ? {'Authorization': `Bearer ${window.localStorage.getItem('token')}`} : {};
    }

    dataProvider() {
        let dataProvider = null !== window.localStorage.getItem('token') ? hydraClient(this.state.api, (url, options = {}) => baseFetchHydra(url, {
            ...options,
            headers: new Headers(this.fetchHeaders()),
        })) : (type, resource, params) => new Promise(function(resolve, reject) {
        });

        return customDataProvider(dataProvider);
    }

    apiDocumentationParser(entrypoint) {

        return parseHydraDocumentation(entrypoint, {headers: new Headers(this.fetchHeaders())})
            .then(
                ({api}) => {
                    return {
                        api
                    }
                },
                (result) => {
                    switch (result.status) {
                        case 401:
                            return Promise.resolve({
                                api: result.api,
                                customRoutes: [{
                                    props: {
                                        path: '/',
                                        render: () => <Redirect to={`/login`}/>,
                                    },
                                }],
                            });

                        default:
                            return Promise.reject(result);
                    }
                }
            );
    }

    render() {

        if (null !== window.localStorage.getItem('token') && null === this.state.api) {
            return (
                <Grid container justify={'center'} alignItems={'center'} style={{height: '100vh'}}>
                    <AD13Loader />
                </Grid>
            )
        }

        const MyLoginPage = () => <Login backgroundImage={AD13_Background}/>;
        const MyDashboard = () => (
            <Card style={{flexGrow: 1}}>
                <Title title={'AD13'} />
                <CardContent>
                    <Typography style={{fontSize: '2rem'}} variant={'title'} align={'center'} gutterBottom>
                        Bienvenue sur la page d'administration de AD13
                    </Typography>
                    <img src={darkRabbit} alt="illustration lapin" height={'400px'} style={{display: 'block', margin: 'auto', marginTop: 32}}/>
                </CardContent>
            </Card>
     );

        return (
            <Admin
                title="AD13"
                i18nProvider={i18nProvider}
                api={this.state.api}
                loginPage={MyLoginPage}
                dashboard={MyDashboard}
                apiDocumentationParser={this.apiDocumentationParser}
                dataProvider={this.dataProvider()}
                theme={theme}
                // appLayout={ Layout }
                authProvider={authProvider}
            >
                <Resource name="associations" list={AssociationList} show={AssociationShow} icon={NaturePeople}
                          edit={AssociationEdit} options={{label: 'Communes'}} title="Communes"/>
                <Resource name="members" list={MemberList} create={MemberCreate} show={MemberShow} icon={Group}
                          edit={MemberEdit}
                          options={{label: 'Membres'}} title="Membres"/>
                <Resource name="articles" list={ArticleList} create={ArticleCreate} show={ArticleShow} icon={Note}
                          edit={ArticleEdit} options={{label: 'Articles'}} title="Articles"/>
                <Resource name="trainings" list={TrainingList} create={TrainingCreate} edit={TrainingEdit} icon={School}
                          show={TrainingShow}
                          title="Formations" options={{label: 'Formations'}}/>
                <Resource name="documents" list={DocumentList} create={DocumentCreate} show={DocumentShow} icon={Note}
                          edit={DocumentEdit} options={{label: 'Documents'}} title="Documents"/>
                <Resource name="article_categories" title="Catégories"/>
                <Resource name="article_statuses" title="Status articles"/>
                <Resource name="assignments" title="Assignations"/>
                <Resource name="association_types" title="AssociationType"/>
                <Resource name="civilities" title="Civility"/>
                <Resource name="media_objects" title="MediaObject"/>
            </Admin>
        )
    }
}
