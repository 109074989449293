import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    Filter,
    TextInput,
} from 'react-admin';
import {DocumentCreate} from "./Create";

const ArticleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Titre" source="title" defaultValue=""/>
    </Filter>
);

export const DocumentList = (props) => (
    <List {...props} title="Articles" filters={<ArticleFilter/>} perPage={30}>
        <Datagrid>
            <TextField source="title" label="Titre"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);
