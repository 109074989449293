import React, {Fragment} from 'react';
import {
    FileInput,
    showNotification
} from 'react-admin';
import {Config} from "../Config";
import {change} from 'redux-form';
import {connect} from 'react-redux';
import CustomFileList from './CustomFileList';
import CustomFileShow from './CustomFileShow';
import AD13Loader from '../Components/AD13Loader';

class CustomFileInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            previewFiles: [],
            loading: false,
            many: true
        };
    }

    componentDidMount() {
        let files = [];
        if (this.props.hasOwnProperty('many')) {
            this.setState(() => ({many: this.props.many}));
        }

        if (this.props.hasOwnProperty('record') && (this.props.record.hasOwnProperty('files') || this.props.record.hasOwnProperty('file'))) {
            files = this.props.record.hasOwnProperty('files') ? this.props.record.files : [this.props.record.file];

            files.forEach(fileId => {
                fetch(Config.api.entryPoint + fileId, {method: 'GET'})
                    .then(res => res.json())
                    .then(data => {
                        this.setState(state => ({previewFiles: state.previewFiles.concat([data])}));
                    });
            });
        }
    }

    onDrop = acceptedFiles => {

        if (0 === acceptedFiles.length) {
            this.props.showNotification('Le fichier n\'a pu être uploadé, il ne doit pas faire plus de 2 Mo', 'warning');
        }
        acceptedFiles.forEach(file => {
                const body = new FormData();
                body.append('file', file);

                this.setState({
                    loading: true
                });

                return fetch(`${Config.api.entryPoint}/media_objects`, {body, method: 'POST'})
                    .then(response => {
                        response.json().then(data => {

                            this.props.recordFilesField(data['@id']);

                            this.setState(state => {
                                    const previewFiles = state.many ? state.previewFiles.concat([data]) : state.previewFiles = [data];

                                    if (true === this.state.many) {
                                        this.props.recordFilesField(previewFiles.reduce((accumulateur, valeurCourante) => accumulateur.concat([valeurCourante['@id']]), []));
                                    } else {
                                        this.props.recordFileField(previewFiles[0]['@id']);
                                    }

                                    return {previewFiles, loading: false}
                                }
                            );
                        });
                    }).catch(() => {
                        this.setState({
                            loading: false
                        });
                    });
            }
        );
    };

    onDelete = fileId => {
        this.setState(state => {

            let previewFiles = state.previewFiles;

            const indexToDelete = previewFiles.findIndex(file => file.uuid === fileId);

            previewFiles.splice(indexToDelete, 1);
            if (true === this.state.many) {
                this.props.recordFilesField(previewFiles.reduce((accumulateur, valeurCourante) => accumulateur.concat([valeurCourante['@id']]), []));
            } else {
                this.props.recordFileField(null);
            }

            return {previewFiles};
        })

    };

    render() {
        return (
            <Fragment>
                {true === this.state.loading && <AD13Loader/> ||
                    <FileInput source="files" label={this.props.label}
                               options={{onDrop: this.onDrop, maxSize: 2 * Math.pow(1024, 2)}}>
                    </FileInput>
                }
                {true === this.state.many &&
                    <CustomFileList previewFiles={this.state.previewFiles} onDelete={this.onDelete} {...this.props}/>}
                {false === this.state.many && 0 < this.state.previewFiles.length &&
                    <CustomFileShow previewFile={this.state.previewFiles[0]} onDelete={this.onDelete} {...this.props}/>}
            </Fragment>
        )
    }
}

export default connect(null, {
    showNotification,
    recordFilesField: val => {
        return change('record-form', "files", val)
    },
    recordFileField: val => {
        return change('record-form', "file", val)
    },
})(CustomFileInput);
