import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    ShowButton,
    EditButton,
    BooleanField,
    Filter,
    TextInput,
    BooleanInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';
// import { CustomPagination } from '../Pagination/CustomPagination';

const AssociationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Commune" source="mapZoneLabel"/>
        <TextInput label="Organisme" source="name"/>
        <ReferenceInput source="associationType" reference="association_types" label="Type">
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <BooleanInput label="Adhérent AD13" source="partOfAD13"/>
    </Filter>
);

export const AssociationList = (props) => (
    <List {...props} title="Communes" filters={<AssociationFilter/>} bulkActionButtons={false} perPage={30}>
        <Datagrid>
            <TextField source="mapZoneLabel" label="Commune"/>
            <TextField source="name" label="Organisme"/>
            <ReferenceField source="associationType" reference="association_types" label="Type" linkType={false}
                            sortBy="associationType.name">
                <TextField source="name"/>
            </ReferenceField>
            <BooleanField source="partOfAD13" label="Adhérent AD13"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);
