import React, {Component, Fragment} from 'react';
import {
    ShowController,
    SimpleShowLayout,
    TextField,
    DateField,
    EmailField,
    EditButton,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    BooleanField,
    Button,
    ShowView,
    showNotification,
    ReferenceField,
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import {refreshView as refreshViewAction} from 'ra-core';
import Accessibility from '@material-ui/icons/Accessibility';
import {connect} from 'react-redux';
import {Config} from "../../Config";

const OrganismeField = ({record = {}}) => <span>{record.mapZoneLabel} - {record.name}</span>;

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const Actions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
    </CardActions>
);

class MemberShow extends Component {

    constructor(props) {
        super(props);
    }

    handleClick = () => {
        let token = window.localStorage.getItem('token');

        const id = this.props.id.replace('/members/', '');

        let myHeaders = new Headers();
        myHeaders.append('authorization', 'Bearer ' + token);
        myHeaders.append('content-type', 'application/json');

        fetch(`${Config.api.entryPoint}/members/activate`, {
            headers: myHeaders,
            body: JSON.stringify({id: id}),
            method: 'POST'
        })
            .then(response => {
                if(!response.ok){
                    this.props.showNotification('Un problème technique est survenu', 'warning');
                    return;
                }

                response.json().then(data => {
                    this.props.refreshView();
                    this.props.showNotification('Le compte est activé, un email de notification a été envoyé à l\'utilisateur');
                }).catch(data => {
                    this.props.showNotification(data);
                })
            }).catch(data => {
            this.props.showNotification(data);
        });
    };

    render() {
        return (
            <ShowController {...this.props} title="Membre">
                {controllerProps => {
                    return <Fragment>
                        <ShowView actions={<Actions />} {...controllerProps}>
                            <SimpleShowLayout>
                                <TextField source="uuid" label="ID"/>
                                <EmailField source="email" label="Email"/>
                                <ReferenceField source="civility" reference="civilities" label="Civilité"
                                                linkType={false}>
                                    <TextField source="name"/>
                                </ReferenceField>
                                <TextField source="firstName" label="Prénom"/>
                                <TextField source="lastName" label="Nom"/>
                                <TextField source="address" label="Adresse" />
                                <TextField source="phoneNumber" label="Télephone" />
                                <TextField source="postalCode" label="Code postal" />
                                <BooleanField source="enabled" label="Actif"/>
                                <DateField source="dateCreate" label="Date de création"/>
                                <ReferenceArrayField source="associations" reference="associations"
                                                     label="Organisme">
                                    <SingleFieldList>
                                        <OrganismeField/>
                                    </SingleFieldList>
                                </ReferenceArrayField>
                                <ReferenceArrayField reference="assignments" source="assignments"
                                                     label="Assignations">
                                    <SingleFieldList linkType={false}>
                                        <ChipField source="label"/>
                                    </SingleFieldList>
                                </ReferenceArrayField>

                            </SimpleShowLayout>
                        </ShowView>
                        {controllerProps.record && false === controllerProps.record.enabled &&
                        <Button onClick={this.handleClick} label="Activer le compte avec envoi de mail">
                            <Accessibility/>
                        </Button>}
                    </Fragment>
                }
                }
            </ShowController>
        )
    }
}

export default connect(null, {showNotification, refreshView: refreshViewAction})(MemberShow);
