import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    ShowButton,
    EditButton,
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    NumberField
} from 'react-admin';

const ArticleFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="category" reference="article_categories" label="Emplacement">
            <SelectInput optionText="name" optionValue={"@id"}/>
        </ReferenceInput>
        <ReferenceInput source="status" reference="article_statuses" label="Status">
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <TextInput label="Titre" source="title" defaultValue=""/>
    </Filter>
);

export const ArticleList = (props) => (
    <List {...props} title="Articles" filters={<ArticleFilter/>} perPage={30}>
        <Datagrid>
            <ReferenceField source="category" reference="article_categories" label="Emplacement" linkType={false}
                            sortBy="category.name">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="status" reference="article_statuses" label="Status" linkType={false}
                            sortBy="status.name">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="title" label="Titre"/>
            <NumberField source="ranking" label="Ordre" defaultValue={0} />
            <DateField source="dateCreate" label="Date création"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);
