import React from 'react';
import {
  Create, SimpleForm, TextInput, email, required, maxLength, ArrayInput, SimpleFormIterator, ReferenceArrayInput,
  SelectArrayInput, AutocompleteArrayInput, BooleanInput, ReferenceInput, SelectInput,
} from 'react-admin';

export const MemberCreate = (props) => (
    <Create { ...props } title="Membre">
        <SimpleForm redirect="show">
            <TextInput source="email" label="Email" type="email" validate={ [required(), email()] } />
            <ReferenceInput source="civility" reference="civilities" label="Civilité">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="firstName" label="Prénom" validate={ [required(), maxLength(255)] } />
            <TextInput source="lastName" label="Nom" validate={ [required(), maxLength(255)] } />
            <TextInput source="address" label="Adresse" validate={maxLength(255)}/>
            <TextInput source="postalCode" label="Code postal" validate={maxLength(10)}/>
            <TextInput source="phoneNumber" label="Télephone" validate={maxLength(20)}/>
            <TextInput source="plainPassword" type="password" label="Mot de passe" validate={ [maxLength(50)] } />
            <BooleanInput label="Actif" source="enabled"/>
            <ReferenceArrayInput source="associations" reference="associations" label="Organisme" filterToQuery={searchText => ({ search: searchText })}>
              <AutocompleteArrayInput optionText={organisme => organisme.mapZoneLabel + ' - ' + organisme.name} />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="assignments" reference="assignments" label="Assignation" validate={ required() }>
              <SelectArrayInput optionText="label" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
