import decodeJwt from "jwt-decode";

class Security {

    constructor() {
        if (!SecurityService) {
            SecurityService = this;
        }
        return SecurityService;
    }

    getRoles() {
        let token = localStorage.getItem('token');

        if (!token) {
            return [];
        }
        const decodedToken = decodeJwt(token);
        return decodedToken.roles;
    }

    hasRole(roles) {
        return this.getRoles().some(element => {
            if(Array.isArray(roles)){
                return roles.some(role => role === element);
            }
            return roles === element;
        });
    }
}

let SecurityService = new Security();

export default SecurityService;
