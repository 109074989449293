import React, {Component} from 'react';
import {Query, Loading} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default class MembersList extends Component {
    render() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Membre</TableCell>
                        <TableCell align="left">Commune</TableCell>
                        <TableCell align="left">Date d'inscription</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.record[this.props.membersFromStatusField].map((member, index) => {
                        let registrationDate = new Date(member.registrationDate);
                        return <TableRow key={index}>
                            <TableCell>{member.firstName} {member.lastName}</TableCell>
                            <TableCell>
                                { 0 < member.associationIds.length ?
                                    <Query type="GET_ONE" resource="associations"
                                           payload={{id: '/associations/' + member.associationIds[0]}}>
                                        {({data, loading, error}) => {
                                            if (loading) {
                                                return <Loading/>;
                                            }
                                            if (error) {
                                                return <p>ERROR</p>;
                                            }
                                            return <div>{data.mapZoneLabel}</div>;
                                        }}
                                    </Query>
                                    : <span>A définir</span>
                                }
                            </TableCell>
                            <TableCell>{registrationDate.toLocaleString("fr-FR")}</TableCell>
                        </TableRow>;
                    })}
                </TableBody>
            </Table>
        )
    }
}
