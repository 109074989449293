import React from 'react';
import Button from '@material-ui/core/Button';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import {Link} from 'react-admin';

export default ({record}) => {
    if(!record || !record.hasOwnProperty('image')) {
        return '';
    }

    let recordDuplicated = {
        name: record.name,
        shortDescription: record.shortDescription,
        description: record.description,
        numberMinOfParticipants: record.numberMinOfParticipants,
        numberMaxOfParticipants: record.numberMaxOfParticipants,
        image: null !== record.image ? '/media_objects/' + record.image.id : null,
    }

    return (
        <Button color={'primary'} size={'small'}
                component={Link}
                to={{
                    pathname: '/trainings/create',
                    state: {record: recordDuplicated},
                }}
        >
            <LibraryAddIcon/>
            Dupliquer
        </Button>
    )
};
