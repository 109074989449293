import React from 'react';
import {
    Show,
    TextField,
    DateField,
    NumberField,
    RichTextField,
    ArrayField,
    Datagrid,
    ImageField,
    SelectField,
    TabbedShowLayout,
    Tab,
    EditButton
} from 'react-admin';
import TrainigStatuses from './Config/TrainigStatuses';
import MembersList from './Component/MembersList';
import DuplicateButton from "./Component/DuplicateButton";
import CardActions from '@material-ui/core/CardActions';
import ExportButton from "./Component/ExportButton";

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    padding: 0,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
};

const PostShowActions = ({basePath, data}) => {

    return (
        <CardActions style={cardActionStyle}>
            <EditButton basePath={basePath} record={data}/>
            <DuplicateButton record={data}/>
            <ExportButton record={data} />
        </CardActions>
    )
};

export const TrainingShow = (props) => (
    <Show actions={<PostShowActions/>} {...props} title="Formation">
        <TabbedShowLayout>
            <Tab label="Détail">
                <TextField source="id" label="ID"/>
                <SelectField source="status" choices={TrainigStatuses} label="status"/>
                <TextField source="name" label="Intitulé"/>
                <RichTextField source="shortDescription" label="Résumé"/>
                <RichTextField source="description" label="Description"/>
                <NumberField source="numberOfParticipants" label="Participants"/>
                <NumberField source="numberMinOfParticipants" label="Nombre minimum de participants"/>
                <NumberField source="numberMaxOfParticipants" label="Nombre maximum de participants"/>
                <ImageField source="image.url"/>
                <ArrayField source="events" label="Dates">
                    <Datagrid>
                        <DateField source="dateStart" label="Commence le"/>
                        <DateField source="dateEnd" label="Fini le"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Membres inscrit">
                <MembersList membersFromStatusField={"registeredMembers"}/>
            </Tab>
            <Tab label="Liste d'attente">
                <MembersList membersFromStatusField={"waitingMembers"}/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
