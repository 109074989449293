import React, {Component, Fragment} from 'react';
import {
    SimpleShowLayout,
    TextField,
    ReferenceField,
    EditButton,
    FileField,
    ShowView,
    ShowController
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import {DocumentCreate} from "./Create";
import CustomFileList from "../CustomFileList";
import CustomFileShow from "../CustomFileShow";

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const Actions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
    </CardActions>
);

export class DocumentShow extends Component {

    render() {
        return (
            <ShowController  {...this.props} title="Membre">
                {controllerProps => {
                    return <Fragment>
                        <ShowView actions={<Actions />} {...controllerProps}>
                            <SimpleShowLayout>
                                <TextField source="uuid" label="ID"/>
                                <TextField source="title" label="Titre"/>
                                <ReferenceField source="file" reference="media_objects" label="Image" linkType={false}>
                                    <FileField source="url"/>
                                </ReferenceField>

                                {controllerProps.record && controllerProps.record.hasOwnProperty('file') &&
                                    <CustomFileShow label={"Uploader une pièce jointe à l'article"}
                                                    previewFile={controllerProps.record.file}/>}
                            </SimpleShowLayout>
                        </ShowView>
                    </Fragment>
                }
                }
            </ShowController>
        )
    }
}
