import React from 'react';

const AD13LoaderAdmin = props => (
  <svg
    style={{
      width:  110,
      height: 110,
      margin:'auto',
    }}
    transform="rotate(-45)"
    {...props}
  >
    <g fill="#F3752B">
      <path d="M0 0h30v30H0z">
        <animate
          attributeName="opacity"
          from={1}
          to={0}
          dur="1s"
          repeatCount="indefinite"
          begin={0}
          fill="freeze"
          values="1;0.75;0.5;0"
          keyTimes="0;0.25;0.5;1"
        />
      </path>
      <path d="M40 40h30v30H40z">
        <animate
          attributeName="opacity"
          from={1}
          to={0}
          dur="1s"
          repeatCount="indefinite"
          begin="0.1s"
          fill="freeze"
          values="1;0.75;0.5;0"
          keyTimes="0;0.25;0.5;1"
        />
      </path>
      <path d="M80 80h30v30H80z">
        <animate
          attributeName="opacity"
          from={1}
          to={0}
          dur="1s"
          repeatCount="indefinite"
          begin="0.2s"
          fill="freeze"
          values="1;0.75;0.5;0"
          keyTimes="0;0.25;0.5;1"
        />
      </path>
    </g>
  </svg>
)


export default AD13LoaderAdmin



