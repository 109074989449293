import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  email,
  NumberInput,
  DisabledInput,
  maxLength,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceField,
  ImageField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import CustomImageInput from '../CustomImageInput';

export const AssociationEdit = (props) => (
  <Edit {...props} title="Commune">
    <SimpleForm>
      <DisabledInput source="uuid" label="ID"/>
      <TextInput source="mapZoneLabel" label="Commune" validate={[required(), maxLength(255)]}/>
      <TextInput source="name" label="Organisme" validate={[required(), maxLength(255)]}/>
      <BooleanInput label="Adhérent AD13" source="partOfAD13"/>
      <ReferenceInput source="associationType" reference="association_types" label="Status"
                      validate={required()}>
        <SelectInput optionText="name"/>
      </ReferenceInput>
      <TextInput source="address" label="Adresse"/>
      <TextInput source="email" label="Email" type="email" validate={[email(), maxLength(255)]}/>
      <TextInput source="websiteUrl" label="Site internet" type="url" validate={maxLength(255)}/>
      <TextInput source="phoneNumber" label="Télephone" validate={maxLength(20)}/>
      <TextInput source="accountant" label="Gérant" validate={maxLength(255)}/>
      <TextInput source="assistant" label="Adjoint" validate={maxLength(255)}/>
      <NumberInput source="numberOfMembers" label="Nombre de membres"/>
      <ReferenceArrayInput source="members" reference="members" label="Membres"
                           filterToQuery={searchText => ({search: searchText})}>
        <AutocompleteArrayInput optionText={member => member.lastName + ' ' + member.firstName}/>
      </ReferenceArrayInput>
      <TextInput source="means" label="Moyens" validate={maxLength(255)}/>
      <RichTextInput source="description" label="Description"/>

      <ReferenceField source="image" reference="media_objects" label="Image" linkType={false}>
        <ImageField source="url"/>
      </ReferenceField>

      <CustomImageInput/>

      <TextInput source="image" label="Image" style={{display: 'none'}}/>
    </SimpleForm>
  </Edit>);
