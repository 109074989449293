import React from 'react';
import {
    Labeled
} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {Config} from "../Config";


export default class CustomFileShow extends React.Component {

    state = {previewFile: []};


    constructor(props) {
        super(props);

        if (props.hasOwnProperty('previewFile')) {
            this.state = {previewFile: props.previewFile};
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.hasOwnProperty('previewFile') && 'object' === typeof this.props.previewFile) {
            this.setState(state => {
                return {previewFile: nextProps.previewFile}
            });
        }
    }

    componentDidMount() {
        if (!this.props.hasOwnProperty('previewFile')) {
            return;
        }

        if('object' !== typeof this.props.previewFile) {
            fetch(Config.api.entryPoint + this.props.previewFile, {method: 'GET'})
                .then(res => res.json())
                .then(data => {
                    this.setState(state => {
                        return {previewFile: data}
                    });
                });
        }
    }

    render() {
        return (<Labeled label="Fichier">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Nom</TableCell>
                        <TableCell align="left">Url</TableCell>
                        <TableCell align="left">Télécharger</TableCell>
                        {this.props.hasOwnProperty('onDelete') && <TableCell align="left">Supprimer</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={this.state.previewFile.name}>
                        <TableCell>
                            {this.state.previewFile.originalName}
                        </TableCell>
                        <TableCell>
                            <a href="javascript:void(0)" id={'file-' + this.state.previewFile.uuid} onClick={() => {
                                // Select the email link anchor text
                                const fileLink = document.querySelector('#file-' + this.state.previewFile.uuid);
                                const range = document.createRange();
                                range.selectNode(fileLink);
                                window.getSelection().addRange(range);

                                try {
                                    // Now that we've selected the anchor text, execute the copy command
                                    document.execCommand('copy');
                                } catch (err) {
                                    console.log('Oops, unable to copy');
                                }

                                // Remove the selections - NOTE: Should use
                                // removeRange(range) when it is supported
                                window.getSelection().removeAllRanges();
                            }} title={"Copier le lien"}>
                                {this.state.previewFile.url}
                                <IconButton aria-label="Copier le lien">
                                    <LinkIcon/>
                                </IconButton>
                            </a>
                        </TableCell>
                        <TableCell>
                            <a href={this.state.previewFile['url']} download title={"Télécharger"}>
                                <IconButton aria-label="Télécharger">
                                    <CloudDownloadIcon/>
                                </IconButton>
                            </a>
                        </TableCell>
                        {this.props.hasOwnProperty('onDelete') && <TableCell>
                            <IconButton title={"Supprimer"} aria-label="Supprimer" onClick={() => {
                                this.props.onDelete(this.state.previewFile['uuid'])
                            }}>
                                <DeleteIcon/>
                            </IconButton>
                        </TableCell>}
                    </TableRow>
                </TableBody>
            </Table>
        </Labeled>)
    }
}
