import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    DateField,
    ShowButton,
    BooleanField,
    EditButton,
    Filter,
    TextInput,
    BooleanInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ReferenceInput,
    SelectInput,
    CardActions,
    CreateButton,
    ExportButton,
    downloadCSV
} from 'react-admin';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'assignments', 'assignments').then(assignments => {
        fetchRelatedRecords(records, 'civility', 'civilities').then(civilities => {
            fetchRelatedRecords(records, 'associations', 'associations').then(associations => {
                const data = records.map(record => {
                    return {
                        Civilite: record.civility ? civilities[record.civility].name : '',
                        Nom: record.lastName,
                        Prenom: record.firstName,
                        Email: record.email,
                        Adresse: record.address,
                        Code_postal: record.postalCode,
                        Telephone: record.phoneNumber,
                        Commune: 0 < record.associations.length ? associations[record.associations[0]].mapZoneLabel : '',
                        Assignation: 0 < record.assignments.length ? assignments[record.assignments[0]].label : '',
                        Actif: record.enabled ? 'oui' : 'non',
                        Date_Creation: record.dateCreate
                    }
                });
                const csv = convertToCSV({data});
                downloadCSV(csv, 'members');
            })
        })
    });
};

const PostActions = ({
                         bulkActions,
                         basePath,
                         currentSort,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                         total
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath}/>
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResults={3000}
        />
    </CardActions>
);

const MemberFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom" source="lastName"/>
        <TextInput label="Prénom" source="firstName"/>
        <TextInput label="Email" source="email"/>
        <BooleanInput label="Actif" source="enabled"/>
        <ReferenceInput source="assignments" reference="assignments" label="Assignation">
            <SelectInput optionText="label"/>
        </ReferenceInput>
    </Filter>
);

export const MemberList = (props) => (
    <List {...props} title="Membres" filters={<MemberFilter/>} actions={<PostActions/>} perPage={30}>
        <Datagrid>
            <TextField source="lastName" label="Nom"/>
            <TextField source="firstName" label="Prénom"/>
            <EmailField source="email" label="Email"/>
            <ReferenceArrayField reference="assignments" source="assignments"
                                 label="Assignations" sortable={false}>
                <SingleFieldList linkType={false}>
                    <ChipField source="label"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField reference="associations" source="associations"
                                 label="Commune" sortable={false}>
                <SingleFieldList linkType={false}>
                    <ChipField source="mapZoneLabel"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <BooleanField source="enabled" label="Actif"/>
            <DateField source="dateCreate" label="Date de création"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);
