import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    SimpleFormIterator,
    NumberInput,
    ArrayInput,
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {DateTimeInput} from 'react-admin-date-inputs';
import frLocale from "date-fns/locale/fr";
import MomentUtils from '@date-io/moment';
import CustomImageInput from '../CustomImageInput';
import RichTextInputConfig from './../../Config/RichTextInputConfig';

MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth;

export const TrainingCreate = (props) => {
    return (
        <Create {...props} title="Formation">
            <SimpleForm redirect="show">
                <TextInput source="name" label="Intitulé" validate={required()}/>
                <RichTextInput source="shortDescription" label="Résumé" toolbar={RichTextInputConfig}
                               validate={required()}/>
                <RichTextInput source="description" label="Description" toolbar={RichTextInputConfig}
                               validate={required()}/>
                <NumberInput source="numberMinOfParticipants" label="Nombre minimum de participants"
                             validate={required()}/>
                <NumberInput source="numberMaxOfParticipants" label="Nombre maximum de participants"
                             validate={required()}/>
                <ReferenceArrayInput source="memberIds" reference="members" label="Participants"
                                     filterToQuery={searchText => ({search: searchText})}>
                    <AutocompleteArrayInput optionText={member => member.lastName + ' ' + member.firstName}/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="waitingMemberIds" reference="members" label="Liste d'attente"
                                     filterToQuery={searchText => ({search: searchText})}>
                    <AutocompleteArrayInput optionText={member => member.lastName + ' ' + member.firstName}/>
                </ReferenceArrayInput>

                <CustomImageInput label={"Image de présentation de la formation"}/>

                <TextInput source="image" label="Image" style={{display: 'none'}}/>

                <ArrayInput source="events" label="Dates">
                    <SimpleFormIterator>
                        <DateTimeInput source="dateStart" label="Commence le" validate={required()}
                                       options={{format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true}}
                                       providerOptions={{utils: MomentUtils, locale: frLocale}}/>
                        <DateTimeInput source="dateEnd" label="Fini le" validate={required()}
                                       options={{format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true}}
                                       providerOptions={{utils: MomentUtils, locale: frLocale}}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>)
};
