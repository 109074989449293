// src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { Config } from "./Config";

import SecurityService from './Service/SecurityService';

// Change this to be your own login check route.
const login_uri = Config.api.entryPoint + '/login_check';

export default (type, params) => {
    switch (type) {
        case AUTH_LOGIN:
            const { username, password } = params;
            const request = new Request(`${login_uri}`, {
                method: 'POST',
                body: JSON.stringify({ username: username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });

            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);



                    return response.json();
                })
                .then(({ token }) => {
                    // const decodedToken = decodeJwt(token);
                    // if('ROLE_ADMIN' === decodedToken.role){
                        localStorage.setItem('token', token); // The JWT token is stored in the browser's local storage
                        window.location.replace('/');

                    if(SecurityService.getRoles().length === 1 && SecurityService.hasRole('ROLE_USER')){
                        return Promise.reject();
                    }

                    // } else {
                    //     return Promise.reject();
                    // }
                });

        case AUTH_LOGOUT:
            localStorage.removeItem('token');
            break;

        case AUTH_ERROR:
            if ('object' === typeof params && params.hasOwnProperty('response') && (401 === params.response.status || 403 === params.response.status)) {
                localStorage.removeItem('token');
                return Promise.reject();
            }

            return Promise.resolve();
            break;

        case AUTH_CHECK:
            return localStorage.getItem('token') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });

        default:
            return Promise.resolve();
    }
}
